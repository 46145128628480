import type { IInwinkEntityV3QueryOptions } from '@@api/front/definitions';
import type { ICommunityRequestManager } from '@@services/apiaccessprovider.definition';
import type { ICommunityGroup } from '@@community/data';
import type { IFrontDatasourceV3 } from '../../../api/front/datasource';
import { defaultPostHeaderForJsonData } from '@@api/index';

export interface IInviteMembers {
    membersToInvite: string[],
    groupInvitationMode: string
}

export function groupDatasourceV3(requestManager: ICommunityRequestManager): Promise<IFrontDatasourceV3<ICommunityGroup>> {
    return import("../../../api/front/datasource").then((mod) => {
        const url = "community/" + requestManager.communityId + "/group";
        return mod.getFrontDataSource<ICommunityGroup>(
            requestManager, url
        );
    });
}

export interface IGroupsQueryOptions extends IInwinkEntityV3QueryOptions {
    registeredGroups?: boolean;
    adminStatus?: string[];
}

export function groupsQuery(
    requestManager: ICommunityRequestManager,
    options?: IGroupsQueryOptions
) {
    const targetOptions = options || {};
    return groupDatasourceV3(requestManager).then((ds) => ds.query(targetOptions));
}

export function groupsCount(
    requestManager: ICommunityRequestManager,
    options?: IGroupsQueryOptions
) {
    const targetOptions = options || {};
    return groupDatasourceV3(requestManager).then((ds) => ds.count(targetOptions));
}

export function groupDetail(
    requestManager: ICommunityRequestManager,
    groupId: string
) {
    const url = `community/${requestManager.communityId}/group/${groupId}/detail`;
    return requestManager.postJson(url, JSON.stringify({
        selects: {
            $all: true,
            memberAccess: { $all: true },
            company: {
                id: true,
                name: true,
                logo: {
                    $all: true
                }
            }
        }
    }), defaultPostHeaderForJsonData);
}

export function registerMember(
    requestManager: ICommunityRequestManager,
    groupId: string,
    text?: string
) {
    const url = `community/${requestManager.communityId}/group/${groupId}/register`;
    return requestManager.postJson(url, JSON.stringify({
        text
    }), defaultPostHeaderForJsonData);
}

export function inviteMembers(
    requestManager: ICommunityRequestManager,
    groupId: string,
    bodyRequest: IInviteMembers
) {
    const url = "community/" + requestManager.communityId + "/group/" + groupId + "/invitemembers";
    return requestManager.postJson(url, JSON.stringify(bodyRequest), defaultPostHeaderForJsonData);
}

export function unregisterMember(
    requestManager: ICommunityRequestManager,
    groupId: string
) {
    const url = `community/${requestManager.communityId}/group/${groupId}/unregister`;
    return requestManager.postJson(url);
}

export function validateJoinRequest(
    requestManager: ICommunityRequestManager,
    groupId: string,
    groupMemberId: string
) {
    const url = `community/${requestManager.communityId}/group/${groupId}/registration/${groupMemberId}/validate`;
    return requestManager.postJson(url);
}

export function declineJoinRequest(
    requestManager: ICommunityRequestManager,
    groupId: string,
    groupMemberId: string,
    text?: string
) {
    const url = `community/${requestManager.communityId}/group/${groupId}/registration/${groupMemberId}/reject`;
    return requestManager.postJson(url, JSON.stringify({
        text
    }), defaultPostHeaderForJsonData);
}

import * as React from 'react';
import { IComment, IPublication } from '@@modules/community/data';
import { IMemberInfoContext, MemberInfoContext } from '../memberinfocontext';
import {
    LikesPreview
} from '@@modules/community/features/feed/blocs/mainfeed/mainbloc/publications/publicationitem/likespreview';
import { userMessageActions } from '@@services/usermessageactions';
import { Entities } from '@inwink/entities/entities';
import { States } from '@@services/services';

interface ICommentLikeCounterProps {
    comment: IComment;
    publication: IPublication;
    userMessageActions?: typeof userMessageActions;
    i18nHelper: Entities.i18nHelper;
    community: States.ICommunityState;
    customcontext: any;
}

export const CommentLikeCounter = (props: ICommentLikeCounterProps) => {
    const [likePreviewOpen, setLikePreviewOpen] = React.useState(false);
    const onNumberClick = (arg: React.MouseEvent<any>, memberContext: IMemberInfoContext) => {
        const _props = {
            ...props,
            memberContext,
            contentId: props.comment?.id
        };
        setLikePreviewOpen(true);
        (props.userMessageActions?.showMessage(
            props.i18nHelper, LikesPreview, _props, arg.target as HTMLElement, null, null, "likepreview-modal") as any)
            .then(() => {
                setLikePreviewOpen(false);
            });
    };
    const nbLikes = props.comment?.likes;
    return <MemberInfoContext.Consumer>
        {(ctx) => {
            return <div
                onClick={nbLikes >= 0 ? (arg) => onNumberClick(arg, ctx) : null}
                className="like-counter clickable"
            >
                <i
                    className="inwink-thumbup-outline"
                />
                <span
                    className={`count ${likePreviewOpen ? "active" : ""}`}>
                    {nbLikes}
                </span>
            </div>;
        }}
    </MemberInfoContext.Consumer>;
};
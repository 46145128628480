import * as React from 'react';
import { Loader } from '@inwink/loader';
import { ICommentsDatasource } from '@@modules/community/data';
import { unEscapeHtmlSpecialChars as unEscapeHtml } from '@inwink/utils';

import "./linkpreview.less";
import type { ILink } from '@@community/data';
export interface ILinkProps {
    link: ILink;
}

export interface ILinkPreview {
    url: string;
    title?: string;
    description?: string;
    imageUrl?: string;
}

export interface ILinkPreviewProps {
    link: ILinkPreview;
    onPreviewLoad: (link: ILinkPreview) => void;
    onRemoveLink: (link: ILinkPreview) => void;
    datasource: ICommentsDatasource;
}

export interface ILinkPreviewState {
    loading: boolean;
}

export class LinkPreview extends React.PureComponent<ILinkPreviewProps, ILinkPreviewState> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        let finalUrl = '';
        try {
            if (new URL(this.props.link.url).hostname){
                finalUrl = this.props.link.url;
            }
        } catch (e) {
            // eslint-disable-next-line max-len
            finalUrl = `${window.location.protocol}//${window.location.hostname}:${window.location.port}/dev.html#${this.props.link.url}`;
        }

        this.props.datasource.getUrlPreview(finalUrl).then(res => {
            console.log('getUrlPreview');
            if (res?.hasData) {
                this.props.onPreviewLoad({
                    url: this.props.link.url,
                    title: res.title,
                    description: res.description,
                    imageUrl: res.imageUrl,
                });

                this.setState({ loading: false });
            }
        }, (err) => {
            console.error("error getting info", err);
        });
    }

    remove = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        this.props.onRemoveLink(this.props.link);
    };

    render() {
        if (!this.props.link.title && !this.props.link.description && !this.props.link.imageUrl) return null;

        if (this.state?.loading) {
            return <Loader />;
        }
    
        return <a href={this.props.link.url} target="_blank" rel="noreferrer" className="linkpreview bloc-lightborder">
            {this.props.link.imageUrl && 
                <img className="linkpicture" src={this.props.link.imageUrl} alt="" />}
            <div className="detail">
                <div className="title headlinefont">{unEscapeHtml(this.props.link.title)}</div>
                <div>{unEscapeHtml(this.props.link.description)}</div>
            </div>
            <button className="btnclose" onClick={this.remove} type="button">
                <i className="inwink-dialog-cancel" />
            </button>
        </a>;
    }
}

export class PublicationLink extends React.PureComponent<ILinkProps, ILinkPreviewState> {
    render() {
        const l = this.props.link;

        if (!l.imageUrl && !l.title && !l.description) return null;

        return <a href={this.props.link.url} target="_blank" rel="noreferrer" className="linkpreview bloc-lightborder">
            {l.imageUrl && <img className="linkpicture" src={l.imageUrl} alt="" />}
            <div className="detail">
                <div className="title headline">{l.title}</div>
                <div>{l.description}</div>
            </div>
        </a>;
    }
}

// eslint-disable-next-line prefer-arrow-callback
export const Links = React.memo(function LinksComponent(props: { links: ILink[] }) {
    if (!props.links) {
        return null;
    }

    return <div className="links">
        {
            props.links.map((l, idx) => <PublicationLink
                key={l.url + "#" + idx}
                link={l}
            />)
        }
    </div>;
});
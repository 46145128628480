import * as React from 'react';
// eslint-disable-next-line import/no-cycle
import { CommentItem, ICommentItemProps } from './commentitem';
// eslint-disable-next-line import/no-cycle
import { CommentsItems } from './commentitems';
import { AppTextLabel } from '@inwink/i18n';

export interface ICommentRepliesProps extends ICommentItemProps {
    showDetailed: boolean;
}

export class CommentReplies extends React.PureComponent<ICommentRepliesProps, any> {
    constructor(props: ICommentRepliesProps) {
        super(props);
        this.state = {
            showAll: false
        };
    }

    render() {
        if (!this.props.comment.replies) {
            return null;
        }

        let content = null;

        if (this.props.showDetailed) {
            content = <CommentsItems
                datacontext={this.props.datacontext}
                datasource={this.props.datasource}
                user={this.props.user}
                community={this.props.community}
                memberInfoContext={this.props.memberInfoContext}
                userMessageActions={this.props.userMessageActions}
                parent={this.props.comment}
                publication={this.props.publication}
                onCommentsChanged={this.props.onCommentsChanged}
                disableBorder
            />;
        } else if (this.props.comment.lastReply) {
            content = <>
                {this.props.comment?.replies > 1 ?
                    <div className="loadmorecomments"><a onClick={() => this.setState({ showAll: !this.state.showAll })}>
                        <AppTextLabel i18n={`community.comment.reply.${!this.state.showAll ? "showall" : "hide"}`} />
                    </a></div> : ""}
                {!this.state.showAll ?
                    <CommentItem
                        {...this.props}
                        comment={this.props.comment.lastReply}
                        parent={this.props.comment}
                        onShowReply={this.props.onShowReply}
                    /> :
                    <CommentsItems
                        datacontext={this.props.datacontext}
                        datasource={this.props.datasource}
                        user={this.props.user}
                        community={this.props.community}
                        publication={this.props.publication}
                        memberInfoContext={this.props.memberInfoContext}
                        userMessageActions={this.props.userMessageActions}
                        parent={this.props.comment}
                        onCommentsChanged={this.props.onCommentsChanged}
                        disableBorder />
                }
            </>;
        }

        return <div className="comment-replies">
            {content}
        </div>;
    }
}

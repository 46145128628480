import * as React from 'react';
import { ILikesResult, getLikes } from '@@modules/community/api/feed';
import { ReactionType } from '@@modules/community/data';
import { ReactionIcon } from '@@modules/community/components/reactions';
import { IMemberListModalProps, MemberListModal } from '@@modules/community/components/reactions/memberlistmodal';
import { States } from '@@services/services';
import { Entities } from '@inwink/entities/entities';

import "./likepreview.less";

export interface ILikesPreviewProps
    extends Omit<IMemberListModalProps<ILikesResult>, 'getData' | 'renderTrailing' | 'i18nTitle'> {
    contentId: string;
    onCompleted?: () => void;
    displayReaction?: boolean;
    community: States.ICommunityState;
    i18nHelper: Entities.i18nHelper;
}

export const LikesPreview = (props: ILikesPreviewProps) => {
    return <MemberListModal<ILikesResult> {...props} className="likepreviewmodalcontent" getData={() => {
        return getLikes(props.community.requestManagers.apiFront, props.contentId);
    }} i18nTitle='community.feed.publication.likes' renderTrailing={(like) => {
        return props.displayReaction ? <div className="react">
            <ReactionIcon reaction={ReactionType[like.likeType ?? "Like"]} i18nHelper={props.i18nHelper} />
        </div> : null;
    }} />;
};


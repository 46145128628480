import * as React from 'react';
import * as debounce from 'lodash/debounce';
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { Entities } from '@inwink/entities/entities';
import type { ICommentsDatasource, ILink } from '@@community/data';
import { ILinkPreview, LinkPreview } from './linkpreview';
import { Mention, MentionsInput } from 'react-mentions';
import { MemberInfoBubble } from '../memberinfo';
import { loginActions, messageGroupRegistrationActions } from '@@modules/module.usermessage';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { bindActionCreators } from 'redux';
import { States } from '@@services/services';
import {wrapReduxStore, IInwinkStore } from '../../../../store';
import { IMemberMention } from '@@modules/community/api/feed';

import "./sendcomment.less";

export interface ISendCommentProps {
    i18nHelper: Entities.i18nHelper;
    datasource: ICommentsDatasource;
    datacontext: Entities.IPageDataContext;
    community?: States.ICommunityState;
    loginActions?: typeof loginActions;
    replyTo?: string;
    groupId?: string;
    onCommentsChanged: (res) => void;
    bigComments?: boolean;
    isPublicGroup?: boolean;
    onAddComment?: () => void;
    user?: States.IAppUserState;
    customcontext?: any;
    store?: IInwinkStore;
    autoFocus?: boolean;
    messageGroupRegistrationActions?: typeof messageGroupRegistrationActions;
}

export interface ISendCommentState {
    text: string;
    links: ILink[];
    sending: boolean;
}

// eslint-disable-next-line no-useless-escape
const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/g;
@connectwith(null, (dispatch) => {
    return {
        loginActions: bindActionCreators(loginActions, dispatch),
        messageGroupRegistrationActions: bindActionCreators(messageGroupRegistrationActions, dispatch)
    };
})
class SendCommentComponent extends React.PureComponent<ISendCommentProps, ISendCommentState> {
    constructor(props) {
        super(props);
        this.checkTextForLinks = debounce(this.checkTextForLinks, 1000);

        this.state = {
            text: "",
            links: [],
            sending: false
        };
    }

    textChanged = (val: { target: { value: string } }) => {
        this.setState({ text: val.target.value });
        this.checkTextForLinks();
    };

    sendComment = (arg?: React.MouseEvent<any>) => {
        arg?.preventDefault();
        return import("@@community/features/user/useractions").then((mod) => {
            mod.userActions.promptUserAccess(this.props.i18nHelper,
                arg.target as HTMLElement)(this.props.store.dispatch, this.props.store.getState).then((canComment) => {
                if (canComment) {
                    this.setState({ sending: true });
                    return this.props.datasource.addComment(this.state.text, this.state.links,
                        this.props.replyTo, this.props.groupId)
                        .then((res: any) => {
                            this.setState({ text: "", links: [], sending: false });
                            this.props.onCommentsChanged(res);
                            if (this.props.onAddComment) {
                                this.props.onAddComment();
                            }
                        });
                }
            });
        });
    };

    isValidContent = () => {
        return this.state.text.trim().length > 2 || this.state.links.length;
    };

    checkTextForLinks = () => {
        const urls = this.state.text.match(urlRegex);
        if (urls && urls.length) {
            const target = urls[urls.length - 1];
            const existing = this.state.links?.find((l) => l.url === target);
            if (existing) {
                return;
            }

            this.setState((prevstate) => {
                const newItem: ILinkPreview = { url: target };
                return Object.assign({}, prevstate, {
                    links: prevstate.links ? [...prevstate.links, newItem] : [newItem]
                });
            });
        }
    };

    removeLink = (link: ILinkPreview) => {
        this.setState({
            links: this.state.links?.filter(l => l.url !== link.url)
        });
    };

    onPreviewLoad = (link: ILinkPreview) => {
        const linkToUpdate = this.state.links?.find(l => l.url === link.url);

        if (link.title) {
            linkToUpdate.title = link.title;
        }
        if (link.description) {
            linkToUpdate.description = link.description;
        }
        if (link.imageUrl) {
            linkToUpdate.imageUrl = link.imageUrl;
        }

        if (!this.state.links.some(l => l.title === link.title ||
            l.description === link.description || l.imageUrl === link.imageUrl
        )) {
            this.setState({
                links: [...this.state.links.filter(l => l.url !== link.url), linkToUpdate]
            });
        }
    };

    fetchMembers = debounce(this.props.datasource.getMembersToMention, 300);

    render() {
        const i18nEntityName = this.props.datasource.i18nEntityName || "comment";
        const placeholder = this.props.i18nHelper
            .translate(`community.${i18nEntityName}.placeholder`);

        const mentions = <MentionsInput
            className="textarea"
            disabled={this.state.sending}
            value={this.state.text}
            onChange={this.textChanged}
            placeholder={placeholder}
            allowSpaceInQuery
            autoFocus={this.props.autoFocus}
        >
            <Mention
                trigger="@"
                className="user-mention"
                data={this.fetchMembers}
                markup="<<link>>@mention[__id__][__display__]<<link>>"
                renderSuggestion={(mm: IMemberMention, query, highlightedDisplay) => (
                    <>
                        <MemberInfoBubble
                            className="small"
                            memberid={mm.id}
                            member={mm.member}
                        />
                        {highlightedDisplay}
                    </>
                )}
            />
        </MentionsInput>;
        const preview = <div className="preview">
            {this.state.links.map((l, idx) => <LinkPreview
                key={l.url + "#" + idx}
                link={l}
                onPreviewLoad={this.onPreviewLoad}
                onRemoveLink={this.removeLink}
                datasource={this.props.datasource}
            />)}
        </div>;        

        return <div className="community-addcomment">
            {this.props.bigComments
                ? <div className="big-comment">
                    {mentions}
                    {preview}
                    <AsyncButton
                        disabled={!this.isValidContent()}
                        onClick={this.sendComment}
                        className="send-comment"
                        i18nLabel={`community.${i18nEntityName}.add.button`}
                    />
                </div>
                : <div className="small-comment">
                    <div className="comment-controls">
                        {mentions}
                        <AsyncButton
                            disabled={!this.isValidContent()}
                            className="send-comment"
                            onClick={this.sendComment}
                        >
                            <i className="inwink-send" />
                        </AsyncButton>
                    </div>
                    {preview}
                </div>}
        </div>;
    }
}
export const SendComment = wrapReduxStore(SendCommentComponent);
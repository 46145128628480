/* eslint-disable no-useless-escape */
import * as React from 'react';
import * as debounce from 'lodash/debounce';
import { DisplayDate } from '@inwink/i18n/displaydate';
import { AppTextLabel } from '@inwink/i18n';
import { Entities } from '@inwink/entities/entities';
import { MemberInfoBubble, MemberInfoItem } from '@@community/components/memberinfo';
import { MoreActions, IContextMenuItem } from '@@community/components/contextmenu/moreactions';
import type { userMessageActions } from '@@services/usermessageactions';
import { IMemberInfoContext } from '@@community/components/memberinfocontext';
import type { ICommentsDatasource, IComment, IPublication, IMember } from '@@community/data';
import { SendComment } from './sendcomment';
// eslint-disable-next-line import/no-cycle
import { CommentReplies } from './replies';
// eslint-disable-next-line import/no-cycle
import { AsyncButton } from '@inwink/buttons/asyncbutton';
import { Links, LinkPreview, ILinkPreview } from './linkpreview';
import { connectwith } from '@inwink/react-utils/decorators/connectwith';
import { withUrlService } from '@@components/urlstatecontext';
import { bindActionCreators } from 'redux';
import { networkingActions } from '@@community/features/networking/actions';
import { Mention, MentionsInput } from 'react-mentions';
import { States } from '@@services/services';
import { CommentLikeCounter } from './likecounter';
import { IMemberMention, getCommentLike, likeComment, unlikeComment } from '@@modules/community/api/feed';
import { loginActions, messageGroupRegistrationActions } from '@@modules/module.usermessage';
import { IInwinkStore, wrapReduxStore } from '@@store/index';

import "./commentitem.less";
import moment from 'moment';

const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/g;
const emailRegex = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
const imgRegex = /\[image name="[a-z-0-9]{1,}"\]/g;

const tagsRegex = new RegExp(`(${urlRegex.source})|(${emailRegex.source})|(${imgRegex.source})`, 'g');
export interface ICommentItemProps {
    i18nHelper?: Entities.i18nHelper;
    datasource: ICommentsDatasource;
    datacontext: Entities.IPageDataContext;
    community: States.ICommunityState;
    networkingActions?: typeof networkingActions;
    comment: IComment;
    parent?: IComment;
    user: States.IAppUserState;
    publication?: IPublication;
    hideReplies?: boolean;
    hideActions?: boolean;
    hideLikes?: boolean;
    onShowReply?: () => void;
    // onShowReplies?: () => void;
    onCommentsChanged: (data) => void;
    userMessageActions?: typeof userMessageActions;
    memberInfoContext: IMemberInfoContext;
    inlineReplies?: boolean;
    bigComments?: boolean;
    memberInfoDisplay?: 'bubble' | 'info';
    removeLightBgText?: boolean;
    isPublicGroup?: boolean;
    groupId?: string;
    store?: IInwinkStore
    customcontext?: any;
    loginActions?: typeof loginActions;
    messageGroupRegistrationActions?: typeof messageGroupRegistrationActions;

}
export interface ICommentItemState {
    menuItems: IContextMenuItem[];
}

@connectwith(null, (dispatch) => {
    return {
        networkingActions: bindActionCreators(networkingActions, dispatch),
        loginActions: bindActionCreators(loginActions, dispatch),
        messageGroupRegistrationActions: bindActionCreators(messageGroupRegistrationActions, dispatch),
    };
})
@withUrlService()
class CommentItemComponent extends React.PureComponent<ICommentItemProps, any> {
    textboxRef = null;

    constructor(props: ICommentItemProps) {
        super(props);
        const menuItems: IContextMenuItem[] = [];
        this.checkTextForLinks = debounce(this.checkTextForLinks, 1000);

        this.textboxRef = React.createRef();

        if (props.datasource.allowEdit(props.comment.memberId)) {
            menuItems.push({
                callback: () => this.setState({ editMode: true }),
                icon: "inwink-pencil",
                title: "actions.update"
            });
        }

        if (props.datasource.allowDelete(props.comment.memberId)) {
            menuItems.push({
                callback: (item, i18nHelper, data, actions) => {
                    (actions.confirm(i18nHelper, {
                        title: i18nHelper.translate("community.comment.remove.confirm"),
                        message: i18nHelper.translate("community.comment.remove.confirm.message"),
                    }) as any).then((confirmed) => {
                        if (confirmed) {
                            return props.datasource.removeComment(props.comment.id).then(() => {
                                this.props.onCommentsChanged(null);
                            });
                        }
                    });
                },
                icon: "inwink-trash",
                title: "actions.delete"
            });
        }

        this.state = {
            menuItems,
            text: this.props.comment.text,
            links: this.props.comment.links || []
        };
    }

    commentsChanged = (res) => {
        this.props.onCommentsChanged(res);
        this.setState({ showReply: false });
    };

    showReply = (arg?: React.MouseEvent<any>) => {
        arg?.preventDefault();
        if (this.props.onShowReply) {
            this.props.onShowReply();
        } else {
            this.setState((prevstate) => {
                return Object.assign({}, { showReply: !(prevstate?.showReply) });
            }, () => {
                const replyContainer = document.getElementById(`add-reply-${this.props.comment.id}`);
                if (replyContainer) {
                    replyContainer.scrollIntoView({ block: "center" });
                }
            });
        }
    };

    showMemberCard = (e) => {
        e.preventDefault();
        let targetMember: IMember;
        if (this.state.text) {
            this.state.text.split("<<link>>").map((word) => {
                if (word.includes('@mention[')) {
                    const data = word.split(/\[|\]/);
                    const [firstname, lastname] = data[3].split(' ');
                    targetMember = {
                        id: data[1],
                        firstname,
                        lastname
                    };
                }
            });
        }
        this.props.networkingActions.showMember(this.props.i18nHelper, e.currentTarget,
            e.currentTarget.dataset.id, null, this.props.comment?.member, targetMember);
    };

    getCommentText() {
        const newText = this.props.comment.text.replace(tagsRegex, val => `<<elem>>${val}<<elem>>`);
        return newText.split(/<<elem>>|<<link>>/).map((word, i) => {
            const key = `${word}${i}`;

            if (word.includes('[image name="') && this.props.comment.forumSubjectAssets?.length) {
                const imgId = word.split('"')[1];
                const imgData = JSON.parse(this.props.comment.forumSubjectAssets.find((img) => img.name === imgId).value);

                return (
                    <figure key={imgData.id}>
                        <img
                            src={imgData.image.thumbLargeUrl}
                            alt={imgData.alttext?.[this.props.i18nHelper.i18n.currentLanguageCode]}
                        />
                        {imgData.legend && (
                            <figcaption className="imagelegend">
                                {imgData.legend[this.props.i18nHelper.i18n.currentLanguageCode]}
                            </figcaption>
                        )}
                    </figure>
                );
            }

            if (word.includes('@mention[')) {
                const data = word.split(/\[|\]/);
                return (
                    <a
                        key={key}
                        href="#"
                        data-id={data[1]}
                        onClick={this.showMemberCard}
                    >
                        {data[3]}
                    </a>
                );
            }
            if (word.match(emailRegex)?.length) {
                return (
                    <a
                        key={key}
                        href={`mailto:${word}`}
                    >
                        {word}
                    </a>
                );
            }
            if (word.match(urlRegex)?.length) {
                return (
                    <a
                        key={key}
                        href={word}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {word}
                    </a>
                );
            }
            return word;
        });
    }

    checkTextForLinks = () => {
        const urls = this.state.text.match(urlRegex);
        if (urls && urls.length) {
            const target = urls[urls.length - 1];
            const existing = this.state.links?.find((l) => l.url === target);
            if (existing) {
                return;
            }

            this.setState((prevstate) => {
                const newItem: ILinkPreview = { url: target };
                return Object.assign({}, prevstate, {
                    links: prevstate.links ? [...prevstate.links, newItem] : [newItem]
                });
            });
        }
    };

    textChanged = (val: { target: { value: string } }) => {
        this.setState({ text: val.target.value });
        this.checkTextForLinks();
    };

    isValidContent = () => {
        return this.state.text?.trim().length > 2 || this.state.links.length;
    };

    cancelEditMode = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        this.setState({
            editMode: false,
            text: this.props.comment.text,
            links: this.props.comment.links || []
        });
    };

    removeLink = (link: ILinkPreview) => {
        this.setState({
            links: this.state.links?.filter(l => l.url !== link.url)
        });
    };

    getCommentDateFormat = (props: ICommentItemProps) => {
        const currentDate = moment();
        const commentDate = moment(props.comment.createdAt);
        const diffBetween = currentDate.diff(commentDate, 'days');
        const format = diffBetween > 7 ? "L LT" : "calendar";
        return format;
    };

    like = (arg: React.MouseEvent<any>) => {
        arg.preventDefault();
        return import("@@community/features/user/useractions").then((mod) => {
            mod.userActions.promptUserAccess(this.props.i18nHelper,
                arg.target as HTMLElement)(this.props.store.dispatch, this.props.store.getState).then((canLike) => {
                if (canLike) {
                    const currentlike = this.state?.like;
                    if (typeof currentlike !== "undefined") {
                        if (currentlike) {
                            console.log("unliking " + this.props.comment.id);
                            return unlikeComment(
                                this.props.community.requestManagers.apiFront,
                                this.props.comment?.id,
                                this.props.publication?.groupId,
                                this.props.publication?.id,
                                this.props.datacontext?.entity?.id,
                                this.props.datacontext?.entityKind
                            ).then((res: any) => {
                                if (res) {
                                    this.props.comment.likes = res.likes;
                                    this.setState({ like: false });
                                }
                            }, (err) => {
                                console.error("error unliking " + this.props.comment.id, err);
                            });
                        }
                        return likeComment(
                            this.props.community.requestManagers.apiFront,
                            this.props.comment?.id,
                            this.props.publication?.groupId,
                            this.props.publication?.id,
                            this.props.datacontext?.entity?.id,
                            this.props.datacontext?.entityKind
                        ).then((res: any) => {
                            if (res) {
                                this.props.comment.likes = res.likes;
                                this.setState({ like: true });
                            }
                        }, (err) => {
                            console.error("error unliking " + this.props.comment.id, err);
                        });
                    }
                }
            });
        });
    };

    onPreviewLoad = (link: ILinkPreview) => {
        const linkToUpdate = this.state.links?.find(l => l.url === link.url);

        if (link.title) {
            linkToUpdate.title = link.title;
        }
        if (link.description) {
            linkToUpdate.description = link.description;
        }
        if (link.imageUrl) {
            linkToUpdate.imageUrl = link.imageUrl;
        }

        if (!this.state.links.some(l => l.title === link.title ||
            l.description === link.description || l.imageUrl === link.imageUrl
        )) {
            this.setState({
                links: [...this.state.links.filter(l => l.url !== link.url), linkToUpdate]
            });
        }
    };

    componentDidMount() {
        if (this.props.user?.currentUser) {
            getCommentLike(
                this.props.community.requestManagers.apiFront,
                this.props.comment?.id,
                this.props.customcontext?.groupId,
                this.props.publication?.id,
                this.props.datacontext?.entity?.id,
                this.props.datacontext?.entityKind)
                .then((res) => {
                    this.setState({ like: res?.liked });
                }, (err) => {
                    console.error("error getting like for " + this.props.publication?.id, err);
                });
        }
    }

    componentDidUpdate() {
        if (this.state.editMode) {
            this.textboxRef.current.focus();
        }
    }

    handleUpdate = () => {
        this.props.datasource.updateComment(
            this.props.comment.id,
            this.state.text,
            this.state.links
        ).then(() => {
            this.props.onCommentsChanged(null);
            this.setState({ editMode: false });
        });
    };

    handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.code === 'Escape') {
            this.setState({
                editMode: false,
                text: this.props.comment.text,
                links: this.props.comment.links || []
            });
        }
    };

    fetchMembers = this.props.datasource.getMembersToMention
        ? debounce(this.props.datasource.getMembersToMention, 300) : null;

    render() {
        let addreply = null;

        if (this.state?.showReply) {
            addreply = <div id={`add-reply-${this.props.comment.id}`}>
                <SendComment
                    datacontext={this.props.datacontext}
                    community={this.props.community}
                    datasource={this.props.datasource}
                    i18nHelper={this.props.i18nHelper}
                    replyTo={this.props.parent ? this.props.parent.id : this.props.comment.id}
                    groupId={this.props.publication?.groupId}
                    onCommentsChanged={this.commentsChanged}
                    bigComments={this.props.bigComments}
                    user={this.props.user}
                    customcontext={this.props.customcontext}
                />
            </div>;
        }

        const replies = this.props.comment.replies;
        const isDisplayInfo = this.props.memberInfoDisplay === 'info';
        const format = this.getCommentDateFormat(this.props);

        return <div className="commentitem">
            <div className={"commentitemcontent " + (isDisplayInfo ? "member-info" : "")}>
                {isDisplayInfo ?
                    <MemberInfoItem
                        memberid={this.props.comment.memberId}
                        member={this.props.comment.member} />
                    :
                    <MemberInfoBubble
                        memberid={this.props.comment.memberId}
                        member={this.props.comment.member}
                    />}
                <div className="comment-detail">
                    <DisplayDate className="comment-date" format={format} component="div" date={this.props.comment.createdAt} />
                    {this.state.editMode ? (
                        <>
                            <div className="comment-controls">
                                {this.props.bigComments ? (
                                    <>
                                        <MentionsInput
                                            className="textarea"
                                            inputRef={this.textboxRef}
                                            disabled={this.state.sending}
                                            value={this.state.text}
                                            onChange={this.textChanged}
                                            onKeyDown={this.handleInputKeyDown}
                                            allowSpaceInQuery
                                        >
                                            <Mention
                                                trigger="@"
                                                className="user-mention"
                                                data={this.fetchMembers}
                                                markup="<<link>>@mention[__id__][__display__]<<link>>"
                                                renderSuggestion={(mm: IMemberMention, query, highlightedDisplay) => (
                                                    <>
                                                        <MemberInfoBubble
                                                            className="small"
                                                            memberid={mm.id}
                                                            member={mm.member}
                                                        />
                                                        {highlightedDisplay}
                                                    </>
                                                )}
                                            />
                                        </MentionsInput>

                                        <AsyncButton
                                            disabled={!this.isValidContent()}
                                            onClick={this.handleUpdate}
                                        >
                                            <i className="inwink-send" />
                                        </AsyncButton>
                                    </>
                                ) : (
                                    <>
                                        <MentionsInput
                                            className="textarea"
                                            inputRef={this.textboxRef}
                                            disabled={this.state.sending}
                                            value={this.state.text}
                                            onChange={this.textChanged}
                                            placeholder={
                                                this.props.i18nHelper.translate("community.feed.publication.placeholder")
                                            }
                                            onKeyDown={this.handleInputKeyDown}
                                            allowSpaceInQuery
                                        >
                                            <Mention
                                                trigger="@"
                                                className="user-mention"
                                                data={this.fetchMembers}
                                                markup="<<link>>@mention[__id__][__display__]<<link>>"
                                                renderSuggestion={({ id }, query, highlightedDisplay) => (
                                                    <>
                                                        <MemberInfoBubble
                                                            className="small"
                                                            memberid={id as string}
                                                        />
                                                        {highlightedDisplay}
                                                    </>
                                                )}
                                            />
                                        </MentionsInput>

                                        <AsyncButton
                                            disabled={!this.isValidContent()}
                                            onClick={this.handleUpdate}
                                        >
                                            <i className="inwink-send" />
                                        </AsyncButton>
                                    </>
                                )}
                            </div>
                            <a href="#" className="bloc-accent" onClick={this.cancelEditMode}>
                                <AppTextLabel i18n="actions.cancel" />
                            </a>
                            <div className="preview">
                                {this.state.links.map((l, idx) => <LinkPreview
                                    key={l.url + "#" + idx}
                                    link={l}
                                    onPreviewLoad={this.onPreviewLoad}
                                    onRemoveLink={this.removeLink}
                                    datasource={this.props.datasource}
                                />)}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={"text" + (this.props.removeLightBgText ? "" : " bloc-lightbg")}>
                                {this.getCommentText()}
                                <Links links={this.state.links} />
                            </div>
                            <div className="comment-actions">
                                <div className="left-actions">
                                    {!this.props.hideLikes ? <button
                                        type="button"
                                        onClick={this.like}
                                        className={`like-btn ${this.state.like ? "liked" : ""}`}
                                    >
                                        <AppTextLabel
                                            i18n={`community.comment.actions.${this.state.like ? "unlike" : "like"}`}
                                        />
                                    </button> : null}
                                    {!this.props.hideReplies ? <button type="button" onClick={this.showReply}>
                                        <AppTextLabel
                                            i18n="community.comment.actions.reply"
                                        />
                                    </button> : null}
                                    {replies > 1 && !this.props.hideReplies ? <>
                                        <span>(</span>
                                        <AppTextLabel
                                            i18n="community.comment.actions.replies"
                                            inject={{ replies: this.props.comment.replies }}
                                            className='nbreplies'
                                        />
                                        <span>)</span>
                                    </> : null}
                                </div>
                                <div className="right-actions">
                                    {this.props.comment?.likes && !this.props.hideLikes ? <CommentLikeCounter
                                        i18nHelper={this.props.i18nHelper}
                                        community={this.props.community}
                                        customcontext={this.props.customcontext}
                                        publication={this.props.publication}
                                        comment={this.props.comment}
                                        userMessageActions={this.props.userMessageActions}
                                    /> : null}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {this.state?.menuItems?.length ? <MoreActions data={this.props} items={this.state?.menuItems} /> : null}
            </div>
            <div className="commentitemreplies">
                {!this.props.hideReplies ? <CommentReplies
                    {...this.props}
                    showDetailed={this.state?.showDetailedReplies}
                    onCommentsChanged={this.commentsChanged}
                    onShowReply={this.showReply}
                /> : null}
                {addreply}
            </div>
        </div>;
    }
}

export const CommentItem = wrapReduxStore(CommentItemComponent);
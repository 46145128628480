import * as React from 'react';
import { Loader } from '@inwink/loader';
import { Entities } from '@inwink/entities/entities';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { AppTextLabel } from '@inwink/i18n/apptextlabel';
import type { userMessageActions } from '@@services/usermessageactions';
import type { IMemberInfoContext } from '@@community/components/memberinfocontext';
import type { IComment, ICommentsDatasource, IPublication } from '@@community/data';
import { IPagerProps, Pager } from '@@components/dynamicpage/entitylist/entitylist.pager';
// eslint-disable-next-line import/no-cycle
import { CommentItem } from './commentitem';
import { States } from '@@services/services';

import "./commentitems.less";

export interface ICommentItemsProps {
    i18nHelper?: Entities.i18nHelper;
    datasource: ICommentsDatasource;
    datacontext: Entities.IPageDataContext;
    user: States.IAppUserState;
    community: States.ICommunityState;
    publication?: IPublication;
    parent?: IComment;
    userMessageActions: typeof userMessageActions;
    onCommentsChanged: (data) => void;
    memberInfoContext: IMemberInfoContext;
    changestamp?: any;
    hideReplies?: boolean;
    hideActions?: boolean;
    inlineReplies?: boolean;
    disableBorder?: boolean;
    bigComments?: boolean;
    usePagination?: boolean;
    maxItems?: number;
}

export interface ICommentItemsState {
    loading: boolean;
    comments?: IComment[];
    continuationToken?: string;
    hasMore?: boolean;
    loadingMore?: boolean;
    pager?: IPagerProps;
    showAll?: boolean;
}

@withI18nHelper()
export class CommentsItems extends React.PureComponent<ICommentItemsProps, ICommentItemsState> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            pager: {
                totalCount: 0,
                pageCount: 0,
                pageSize: 0,
                currentPage: 0,
                swipedTo: (currentPage) => {
                    this.setState({
                        pager: {
                            ...this.state.pager,
                            currentPage
                        }
                    });
                }
            }
        };
    }

    componentDidMount() {
        this.loadComments();
    }

    loadComments() {
        this.props.datasource.getComments({
            parentId: this.props.parent?.id,
            currentPage: this.state.pager.currentPage
        }).then((res) => {
            this.setState({
                loading: false,
                comments: res.data,
                hasMore: !!res.continuationToken,
                continuationToken: res.continuationToken,
                pager: {
                    ...this.state.pager,
                    totalCount: res.totalCount,
                    pageCount: res.totalCount / res.pageSize,
                    pageSize: res.pageSize
                }
            });
        });
    }

    loadMoreItems = () => {
        this.setState({ loadingMore: true });
        this.props.datasource.getComments({
            parentId: this.props.parent?.id,
            continuationToken: this.state.continuationToken
        }).then((res) => {
            this.setState((prevstate) => {
                const list = [...res.data, ...prevstate.comments];
                return Object.assign({}, prevstate, {
                    comments: list,
                    loadingMore: false,
                    hasMore: !!res.continuationToken,
                    continuationToken: res.continuationToken
                });
            });
        });
    };

    componentDidUpdate(prevprops: ICommentItemsProps, prevstate: ICommentItemsState) {
        if (prevprops.changestamp !== this.props.changestamp) {
            this.commentsChanged();
        }
        if (prevstate.pager.currentPage !== this.state.pager.currentPage) {
            this.loadComments();
        }
    }

    commentsChanged = () => {
        this.setState({ loading: true });
        this.loadComments();
        // this.props.onCommentsChanged(null);
    };

    renderMore() {
        if (this.state.hasMore) {
            if (this.state.loadingMore) {
                return <div className="loadmorecomments">
                    <Loader />
                </div>;
            }

            return <div className="loadmorecomments"><a onClick={this.loadMoreItems}>
                <AppTextLabel i18n={`community.${this.props.datasource.i18nEntityName || "comment"}.loadmore`} />
            </a></div>;
        }

        return null;
    }

    renderShowAll() {
        return <div className="loadmorecomments"><a onClick={() => this.setState({showAll: true})}>
            <AppTextLabel i18n="community.comment.showall" />
        </a></div>;
    }

    render() {
        let content = null;
        let loadMore;
        if (this.state.loading) {
            content = <Loader />;
        } else if (this.state.comments?.length) {
            let comments;
            if (this.props.maxItems && !this.state.showAll) {
                comments = this.state.comments?.slice(0, this.props.maxItems);
            } else {
                comments = this.state.comments;
            }
            content = <div
                id={this.props.parent ? `comment-items-${this.props.parent.id}` : ""}
                className="comment-items-wrapper">{comments
                    ?.map((c) => (
                        <CommentItem
                            key={c.id}
                            community={this.props.community}
                            datacontext={this.props.datacontext}
                            memberInfoDisplay='info'
                            datasource={this.props.datasource}
                            comment={c}
                            publication={this.props.publication}
                            user={this.props.user}
                            parent={this.props.parent}
                            inlineReplies={this.props.inlineReplies}
                            i18nHelper={this.props.i18nHelper}
                            hideReplies={this.props.hideReplies}
                            hideActions={this.props.hideActions}
                            userMessageActions={this.props.userMessageActions}
                            onCommentsChanged={this.commentsChanged}
                            memberInfoContext={this.props.memberInfoContext}
                            bigComments={this.props.bigComments}
                        />
                    ))}</div>;
        }
        if (this.props.maxItems && !this.state.showAll && !this.state.hasMore) {
            loadMore = this.renderShowAll();
        }
        return <section className="iw-communitycomments items-comments">
            {this.state.comments?.length
                ? <div className={"comment-items"/* + (this.props.disableBorder ? "" : " bloc-lightborder") */}>
                    <AppTextLabel
                        component="h4"
                        i18n={`community.${this.props.datasource.i18nEntityName || "comment"}.feed.title`}
                        className="list-title"
                    />
                    {!this.props.usePagination && this.renderMore()}
                    {content}
                    {loadMore}
                </div>
                : null}

            {this.props.usePagination && (
                <Pager
                    {...this.state.pager}
                    hideCounter
                />
            )}
        </section>;
    }
}
